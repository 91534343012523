import axios from "../instance";
import addQuery from "../../utils/addQuery";

export default {
  // Get All
  getAll: async (payload) => {
    const result = await addQuery(payload);

    return axios.api
      .get(`/product?${result}`)
      .then((response) => response.data);
  },
  getAllByWarehouse: (warehouse) => {
    if (warehouse) {
      return axios.api
        .get(`/product?warehouse=${warehouse}`)
        .then((response) => response.data);
    } else {
      return axios.api.get(`/product`).then((response) => response.data);
    }
  },
  // Get All

  // search: (body, pos, search_by) => {
  search: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/product/search?${result}`, body)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api.get(`/product/${id}`).then((response) => response.data);
  },
  create: (body) => {
    return axios.api.post("/product", body).then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/product/${id}`, body)
      .then((response) => response.data);
  },
  multiUpdate: (body, step) => {
    return axios.api
      .post(`/product/multi?step=${step}`, body)
      .then((response) => response.data);
  },
  multiUpdatePo: (body, step) => {
    return axios.api
      .post(`/product/multiPo?step=${step}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api.delete(`/product/${id}`).then((response) => response.data);
  },

  // search by code or barcode
  searchByCode: async (payload) => {
    const result = await addQuery(payload);

    return axios.api
      .get(`/product?${result}`)
      .then((response) => response.data);
  },

  searchByBarcode: async (payload) => {
    const result = await addQuery(payload);

    return axios.api
      .get(`/product?${result}`)
      .then((response) => response.data);
  },

  //exp date
  searchExpByDate: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/productExp/expire/searchExpire?${result}`, body)
      .then((response) => response.data);
  },
  getAllExpire: async (payload) => {
    const result = await addQuery(payload);

    return axios.api
      .get(`/productExp/expire/list?${result}`)
      .then((response) => response.data);
  },
  updateExp: (body) => {
    return axios.api
      .post(`/productExp/expire`, body)
      .then((response) => response.data);
  },
  deleteExp: (id) => {
    return axios.api
      .delete(`/productExp/expire/${id}`)
      .then((response) => response.data);
  },
};
