import axios from "../instance";
import addQuery from "../../utils/addQuery";

export default {
  getAll: async (payload) => {
    const result = await addQuery(payload);
    return axios.api
      .get(`/mainProductGroup?${result}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/mainProductGroup/${id}`)
      .then((response) => response.data);
  },
  search: async (body, payload) => {
    const result = await addQuery(payload);
    return axios.api
      .post(`/mainProductGroup/search?${result}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/mainProductGroup", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/mainProductGroup/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/mainProductGroup/${id}`)
      .then((response) => response.data);
  },
};
