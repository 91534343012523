import warehouse from "./warehouse";
import mainProduct from "./mainProduct";
import subProduct from "./subProduct";
import product from "./product";
import cutOffStock from "./cutOffStock";

export default {
  warehouse,
  mainProduct,
  subProduct,
  product,
  cutOffStock,
};
