import axios from "../instance";
// const companyId = localStorage.getItem("companyId");
import addQuery from "../../utils/addQuery";

export default {
  // getAll: async (expired_product, state) => {
  getAll: async (payload) => {
    const result = await addQuery(payload);

    return axios.api
      .get(`/cutOffStock?${result}`)
      .then((response) => response.data);

    // if (state.status && !state.status2 && !state.status3) {
    //   console.log("1");
    //   return axios.api
    //     .get(
    //       `/cutOffStock?expired_product=${expired_product}&status=${state.status}&companyId=${companyId}`
    //     )
    //     .then((response) => response.data);
    // }

    // if (state.status && state.status2 && !state.status3) {
    //   console.log("2");
    //   return axios.api
    //     .get(
    //       `/cutOffStock?expired_product=${expired_product}&status=${state.status}&status=${state.status2}&companyId=${companyId}`
    //     )
    //     .then((response) => response.data);
    // }

    // if (state.status && state.status2 && state.status3) {
    //   console.log("3");
    //   return axios.api
    //     .get(
    //       `/cutOffStock?expired_product=${expired_product}&status=${state.status}&status=${state.status2}&status=${state.status3}&companyId=${companyId}`
    //     )
    //     .then((response) => response.data);
    // }
  },

  // out of order
  getAllItem: (warehouseId) => {
    // return axios.api
    //   .get(
    //     `/cutOffStock/items?warehouseId=${warehouseId}&companyId=${companyId}`
    //   )
    //   .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/cutOffStock/${id}`)
      .then((response) => response.data);
  },
  search: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/cutOffStock/search?${result}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/cutOffStock", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/cutOffStock/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/cutOffStock/${id}`)
      .then((response) => response.data);
  },
  deleteItem: (id) => {
    return axios.api
      .delete(`/cutOffStock/item/${id}`)
      .then((response) => response.data);
  },
};
