import axios from "../instance";
import addQuery from "../../utils/addQuery";

export default {
  getAll: async (payload) => {
    const result = await addQuery(payload);
    return axios.api
      .get(`/warehouse?${result}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api.get(`/warehouse/${id}`).then((response) => response.data);
  },
  search: async (body, payload) => {
    const result = await addQuery(payload);
    return axios.api
      .post(`/warehouse/search?${result}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api.post("/warehouse", body).then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/warehouse/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/warehouse/${id}`)
      .then((response) => response.data);
  },
};
